// 1. 找词找句-pc、2. 找词找句-小程序、3. 辅助阅读-插件、辅助阅读-pc、4. DeepChat
export enum ProductSource {
  WantWordQuotes = 1,
  WeApp = 2,
  Extension = 3,
  DeepChat = 4,
  Lingowhale = 5,
  Lingo = 7,
}

// 101.找词找句-web,201.找词找句-小程序,301.阅读辅助-插件,302.阅读辅助-语鲸web,303.阅读辅助-语鲸官网,401.DeepChat,501.语鲸
export enum ProductSubSource {
  WantWordQuotes = 101,
  WeApp = 201,

  Plugin = 301, //阅读辅助-插件
  PluginLingowhale = 302, //阅读辅助-语鲸web
  PluginWebsite = 303, //阅读辅助-语鲸web

  DeepChat = 401,
  Lingowhale = 501,
  LingoMini = 701,
  LingoWebsite = 703,
}

export enum ProductName {
  DeepLang = "深言达意",
  Lingo = "灵狗"
}

// 用户通过电话号码校验类型：适用于登录、修改手机号、修改密码、销毁账号等场景
// 1. 登录 2. 绑定手机号 3. 变更手机号-老手机号校验 4. 更新密码 5. 设置/忘记密码 6. 销毁账号
export enum MobileActions {
  Login = 1,
  BindMobile = 2,
  UpdateMobile = 3,
  UpdatePwd = 4,
  ResetPwd = 5, // 重置密码，需要登录态
  Destroy = 6,
  SettingPwd = 7,
  ForgetPwd = 8, // 忘记密码，不需要登录态
}

// 修改密码动作
export enum PasswordActions {
  UpdatePwd = 4,
  ResetPwd = 5,
  SettingPwd = 7,
  ForgetPwd = 8,
}

// 修改手机号动作
export enum PhoneActions {
  BindMobile = 2,
  UpdateMobile = 3,
}

// 显示弹窗类型，不同类型对应不同的弹窗交互逻辑
export enum ModalShowType {
  Login = 1, // 登录
  SettingPwd = 2, // 首次登录，设置密码；个人中心主动调用
  MobileUpdate = 3, // 修改手机号
  PwdUpdate = 4, // 修改密码
  PwdForget = 5, // 忘记密码
  PwdReset = 14, // 重置密码
  Destroy = 6, // 注销
  AuthenticatedPwdForget = 7, //忘记密码验证用户
  LoginPwd = 8, //密码登录
  AuthenticatedPwdUpdate = 9, //修改密码验证用户
  AuthenticatedUpdateMobile = 10, //修改手机号验证用户
  AuthenticatedDestroy = 11, //注销验证用户
  PwdForgetUpdate = 12, //修改密码忘记密码
  MobileBind = 13, // 绑定手机号
}

export enum AuthenticatedType {
  UpdatePwd = 4, //更新密码
  PwdReset = 14, // 重置密码
  PwdForget = 5, //忘记密码
  PwdForgetUpdate = 2, //修改密码忘记密码
  Destroy = 6, //注销账号
  UpdateMobile = 3, //更新手机号
}

export enum LoginType {
  CODE_LOGIN = "codeLogin",
  PASSWORD_LOGIN = "passWordLogin",
}

export enum LoginFromType {
  PHONE = "phone",
  WECHAT = "wechat",
  PLUGIN = "plugin",
  ACCOUNT = "account",
  BIND_PHONE = "bind-phone",
}
