// eventBus.ts
import { AxiosError } from "axios";
import EventEmitter from "events";
import { IUserInfo } from "../type";
import { ApiResponseError } from "./await-to";

// 合并类型：新类型将覆盖旧的类型
export type Merge<M, N> = Omit<M, Extract<keyof M, keyof N>> & N;

export enum PassportEventMsg {
  LOGIN = "passport-login",
  LOGOUT = "passport-logout",
  DESTROYED = "passport-destroyed",
  MODEL_HIDE = "model-hide",
  LOGIN_EXPIRED = "login-expired",
  PASSWORD_SETTING = "password-setting",
}

export interface ILogin {
  error: ApiResponseError | null;
  result: IUserInfo;
}

export interface ILogout {
  error: ApiResponseError | null;
  result: any;
}

interface EventMap {
  [PassportEventMsg.LOGIN]: ILogin;
  [PassportEventMsg.LOGOUT]: ILogout;
  [PassportEventMsg.DESTROYED]: ILogout;
  [PassportEventMsg.MODEL_HIDE]: Object;
  [PassportEventMsg.LOGIN_EXPIRED]: Object;
  [PassportEventMsg.PASSWORD_SETTING]: Object;
}

export const PassportEvent = new EventEmitter();

type EventFn = <T extends keyof EventMap = keyof EventMap>(
  eventName: T,
  listener: (data: EventMap[T], ...args: any[]) => void,
) => ComplexEventEmitter;

export type ComplexEventEmitter = Merge<
  EventEmitter,
  {
    emit: <T extends keyof EventMap = keyof EventMap>(
      eventName: T,
      data?: EventMap[T],
      ...args: any[]
    ) => boolean;
    on: EventFn;
    off: EventFn;
    removeListener: EventFn;
    addListener: EventFn;
  }
>;

const complexPassportEvent = PassportEvent as ComplexEventEmitter;

export default complexPassportEvent;
