"use client";

import { Modal, ModalProps } from "@deeplang/dui";
import { useDeepGuard } from "../../index";
import {
  ModalShowType,
  LoginType,
  AuthenticatedType,
} from "../../utils/constants";
import storage from "../../utils/storage";
import LoginForm from "../../components/login-form";
import MobileUpdate from "../../components/mobile-update";
import PasswordUpdate from "../../components/password-update";
import Destroy from "../../components/destroy";
import Authenticated from "../../components/authenticated";
import PasswordReset from "../../components/pwd-reset";
import PasswordSetting from "../../components/password-setting";
import MobileBind from "../../components/mobile-bind";
import PasswordForget from "../../components/password-forget";

const LoginModal = (props: ModalProps) => {
  const { style = {} } = props;
  const { state, hide } = useDeepGuard();

  return (
    <Modal
      footer={null}
      open={state.modalVisible}
      onCancel={hide}
      style={{ zIndex: 10001, ...style }}
      centered
      width={"auto"}
      {...props}
    >
      {state.showType === ModalShowType.Login && <LoginForm />}
      {state.showType === ModalShowType.LoginPwd && (
        <LoginForm type={LoginType.PASSWORD_LOGIN} />
      )}
      {state.showType === ModalShowType.LoginWeixin && <LoginForm type={LoginType.WEIXIN_LOGIN} />}
      {state.showType === ModalShowType.Destroy && <Destroy />}

      {state.showType === ModalShowType.SettingPwd && <PasswordSetting />}
      {state.showType === ModalShowType.PwdForget && <PasswordForget />}
      {state.showType === ModalShowType.PwdUpdate && <PasswordUpdate />}
      {state.showType === ModalShowType.PwdReset && <PasswordReset />}

      {state.showType === ModalShowType.PwdForgetUpdate && (
        <PasswordReset type={AuthenticatedType.PwdForgetUpdate} />
      )}
      {state.showType === ModalShowType.MobileUpdate && <MobileUpdate />}
      {state.showType === ModalShowType.MobileBind && <MobileBind />}

      {state.showType === ModalShowType.AuthenticatedPwdForget && (
        <Authenticated type={AuthenticatedType.PwdForget} />
      )}
      {state.showType === ModalShowType.AuthenticatedPwdUpdate && (
        <Authenticated type={AuthenticatedType.UpdatePwd} />
      )}
      {state.showType === ModalShowType.AuthenticatedUpdateMobile && (
        <Authenticated type={AuthenticatedType.UpdateMobile} />
      )}
      {state.showType === ModalShowType.AuthenticatedDestroy && (
        <Authenticated type={AuthenticatedType.Destroy} />
      )}
    </Modal>
  );
};

export default LoginModal;
