// INSERT COMPONENT HERE
import { LightbulbBold, QuotesBold, ListNumbersBold, CheckBold, ArrowSquareOutBold, ArrowLeftBold, ShareFatBold } from "./..";
var boldIcons = [
// INSERT DEMO HERE
{
  name: "LightbulbBold",
  Component: LightbulbBold
}, {
  name: "QuotesBold",
  Component: QuotesBold
}, {
  name: "ListNumbersBold",
  Component: ListNumbersBold
}, {
  name: "CheckBold",
  Component: CheckBold
}, {
  name: "ArrowSquareOutBold",
  Component: ArrowSquareOutBold
}, {
  name: "ArrowLeftBold",
  Component: ArrowLeftBold
}, {
  name: 'ShareFatBold',
  Component: ShareFatBold
}];
export default boldIcons;