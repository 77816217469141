import storage from "./storage";
import { LoginResponse, TRefreshTokenResponse } from "../api/type";
import deepGuardConfig from "../config";
import { set } from "lodash-es";
import { IUserInfo } from "../type";

export const getPrefixKeys = () => {
  let uid = "UID";
  let bid = "BID";
  let authToken = "AuthToken";
  let accessToken = "AccessToken";

  const { prefix } = deepGuardConfig.getConfig();
  if (prefix) {
    uid = `${prefix}-UID`;
    bid = `${prefix}-BID`;
    authToken = `${prefix}-AuthToken`;
    accessToken = `${prefix}-AccessToken`;
  }

  return { uid, bid, authToken, accessToken };
};

export const setHeadersAuthInfo = async (headers: any) => {
  if (!headers) headers = {};
  const keys = getPrefixKeys();
  const [uid, bid, authToken, accessToken] = await Promise.all([
    storage.get(keys.uid),
    storage.get(keys.bid),
    storage.get(keys.authToken),
    storage.get(keys.accessToken),
  ]);

  const AnonymousID = sessionStorage.getItem("AnonymousID");

  set(headers, "Content-Type", "application/json");
  set(headers, "U-Id", uid);
  set(headers, "B-Id", bid);
  set(headers, "Auth-Token", authToken);
  set(headers, "Access-Token", accessToken);
  set(headers, "Anonymous-Id", AnonymousID);
};

// 缓存授权信息
export const setAuthInfo = (data: LoginResponse | TRefreshTokenResponse) => {
  const { uid, bid, authToken, accessToken } = getPrefixKeys();
  // 刷新token时，不需要更新uid
  if (data.uid) {
    storage.set(uid, data.uid);
  }
  storage.set(bid, data.b_id);
  storage.set(authToken, data.auth_token);
  storage.set(accessToken, data.access_token);
};

// 移除缓存中的授权信息
export const removeAuthInfo = () => {
  const { uid, bid, authToken, accessToken } = getPrefixKeys();
  storage.remove(uid);
  storage.remove(bid);
  storage.remove(authToken);
  storage.remove(accessToken);
  storage.remove("deep_user");
};

// 登录态校验
export const loginVerification = () => {
  const AccessToken = storage.get("AccessToken");
  console.log("-----AccessToken------", AccessToken);
  return !!AccessToken;
};

export enum PostMessage {
  PC_LOGIN = "LINGOWHALE_PC_LOGIN",
  PLUGIN_LOGIN = "LINGOWHALE_PLUGIN_LOGIN",
  PC_LOGOUT = "LINGOWHALE_PC_LOGOUT",
  PLUGIN_LOGOUT = "LINGOWHALE_PLUGIN_LOGOUT",
}

export const postLoginMessage = async (postType: PostMessage, data?: any) => {
  const type = `${postType}_POST`;
  window.postMessage({ type, data }, "*");
};

export const receiveLoginMessage = async (
  postType: PostMessage,
  cb: (data: IUserInfo) => void,
) => {
  const receiveType = `${postType}_POST`;
  window.addEventListener("message", receiveMessage, false);

  function receiveMessage(event: { type: string; data: any }) {
    const { type, data } = event.data;
    if (type === receiveType) {
      cb(data);
      // window.removeEventListener('message', receiveMessage);
    }
  }
};

export const getLoginStatus = async () => {
  const { accessToken } = getPrefixKeys();
  const token = await storage.get(accessToken);
  return token ? true : false;
};
