import CryptoJS from "crypto-js";
import passportConfig from "../config";

export const encrypt = (text: string) => {
  const key = CryptoJS.enc.Utf8.parse(passportConfig.secretKey.aeskey);
  const iv = CryptoJS.enc.Utf8.parse(passportConfig.secretKey.aesiv);
  const srcs = CryptoJS.enc.Utf8.parse(text);

  const encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return encrypted.toString();
};

export const decrypt = (text: string) => {
  const key = CryptoJS.enc.Utf8.parse(passportConfig.secretKey.aeskey);
  const iv = CryptoJS.enc.Utf8.parse(passportConfig.secretKey.aesiv);

  const decrypted = CryptoJS.AES.decrypt(text, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};
