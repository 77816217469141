import { Button, Form, Input } from "@deeplang/dui";
import cx from "classnames";
import styles from "./index.module.css";
import styleCommon from "../style/index.module.css";
import {
  ModalShowType,
  PasswordActions,
  errorText,
  useDeepGuard,
} from "../../index";
import to from "../../utils/await-to";

type FieldType = {
  oldPwd?: string;
  newPwd?: string;
  newPwdConfirm?: string;
};

const PasswordUpdate = () => {
  const deepGuard = useDeepGuard();
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    if (values.oldPwd) {
      if (values.oldPwd === values.newPwd) {
        form.setFields([{ name: "newPwd", errors: ["不能与原密码相同"] }]);
        return false;
      }
      const data = {
        oldPwd: values.oldPwd,
        newPwd: values.newPwd,
        action: PasswordActions.UpdatePwd,
      };
      const [err] = await to(deepGuard.updatePassword(data));

      if (err) {
        err.code === 209 &&
          form.setFields([{ name: "oldPwd", errors: [errorText(err.code)] }]);
        return;
      }
      deepGuard.hide();
      setTimeout(() => {
        window.open("/", "_self");
      }, 1000);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div
      className={cx(styleCommon.pl16, styleCommon.pr16)}
      style={{ width: " 292px" }}
    >
      <h3 className={styleCommon.title}>修改密码</h3>
      <Form
        form={form}
        validateTrigger={["onBlur"]}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item<FieldType>
          className={styleCommon.formItemContent}
          name="oldPwd"
          rules={[
            {
              required: true,
              message: "至少为6个字符，需包含数字和字母，不支持符号",
            },
            {
              pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
              message: "至少为6个字符，需包含数字和字母，不支持符号",
            },
          ]}
        >
          <Input name="password" placeholder="请输入原密码" maxLength={50} />
        </Form.Item>
        <Form.Item<FieldType>
          className={cx(styleCommon.formItemContent)}
          name="newPwd"
          rules={[
            {
              required: true,
              message: "至少为6个字符，需包含数字和字母，不支持符号",
            },
            {
              pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
              message: "至少为6个字符，需包含数字和字母，不支持符号",
            },
          ]}
        >
          <Input name="newPwd" placeholder="请输入新密码" maxLength={50} />
        </Form.Item>
        <Form.Item<FieldType>
          className={cx(styleCommon.formItemContent)}
          name="newPwdConfirm"
          dependencies={["newPwd"]}
          rules={[
            {
              required: true,
              message: "请再次输入新密码",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPwd") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("两次密码输入不一致!"));
              },
            }),
          ]}
        >
          <Input
            name="newPwdConfirm"
            placeholder="请再次输入新密码"
            maxLength={50}
          />
        </Form.Item>
        <div className={styleCommon.mb20}>
          <span
            className={cx(styleCommon.fs12, styles.formForgetText, styles.bold)}
            onClick={() => {
              deepGuard.show(ModalShowType.PwdReset);
            }}
          >
            忘记密码
          </span>
        </div>
        <Form.Item
          wrapperCol={{ span: 24 }}
          className={styleCommon.formItemContent}
        >
          <Button
            type="primary"
            className={cx(styleCommon.fullWidth)}
            htmlType="submit"
          >
            确定
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordUpdate;
