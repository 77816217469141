// import apiDebugMap from '@/config/apiDebugMap';
import axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { removeAuthInfo, setAuthInfo, setHeadersAuthInfo } from "./index";
import to from "./await-to";

import api from "../api/user";
import { deepGuardConfig } from "../index";
import { PassportEvent, PassportEventMsg } from "./event-emitter";
import { nanoid } from "nanoid";

export enum ERROR_CODES {
  SUCCESS = 0, // 成功
  TOKEN_NOT_FOUND = 986, // 不存在 token
  AUTH_TOKEN_EXPIRED = 987, // auth-token 过期
  ACCESS_TOKEN_EXPIRED = 988, // access-token 过期，刷新token
  LOGIN_EXPIRED = 995, // 登录过期，重新登录
}

export interface ApiResponse<T> {
  code: number;
  msg: string;
  data: T;
}

let baseURL = process.env.NEXT_PUBLIC_PASSPORT_URL;

// if (deepGuardConfig.env === "qa") {
//   baseURL = "https://qa-api-passport.lingoreader.cn";
// } else if (deepGuardConfig.env === "pre") {
//   baseURL = "https://pre-api-passport.lingoreader.cn";
// } else if (deepGuardConfig.env === "prod") {
//   baseURL = "https://api-passport.lingoreader.cn";
// }

const service = axios.create({
  baseURL,
  timeout: 30000,
  // withCredentials: true,
});

service.interceptors.request.use(
  async (config) => {
    config.headers["Trace-Id"] = nanoid();
    await setHeadersAuthInfo(config.headers);
    return config;
  },
  (error) => Promise.reject(error),
);

service.interceptors.response.use(
  async (response: AxiosResponse<ApiResponse<any>>) => {
    const { status, data, config } = response;

    // Token 失效, 刷新token后重新请求
    // @ts-expect-error
    if (data.code === ERROR_CODES.ACCESS_TOKEN_EXPIRED && !config.hasRefresh) {
      const result = await refreshToken(config, service);
      return result;
    }

    // 登录过期
    if (data.code === ERROR_CODES.LOGIN_EXPIRED) {
      removeAuthInfo();
      PassportEvent.emit(PassportEventMsg.LOGIN_EXPIRED);
      return Promise.reject(data);
    }

    if (status !== 200 || data.code !== 0) {
      return Promise.reject(data);
    }

    return Promise.resolve(data.data);
  },
  (err) => {
    return Promise.reject(err);
  },
);

export async function refreshToken(config: any, service: AxiosInstance) {
  const [err1, result1] = await to(api.refreshToken());
  if (err1) {
    removeAuthInfo();
    return Promise.reject(err1);
  }
  setAuthInfo(result1);
  config.hasRefresh = true;
  const [err2, result2] = await to<any>(service(config));
  if (err2) {
    removeAuthInfo();
    return Promise.reject(err2);
  }
  return result2;
}

// let promise;

// export async function refreshToken1() {
//   if (promise) return;

//   promise = new Promise(async (resolve, reject) => {
//     const [err1, result] = await to(api.refreshToken());
//     if (err1) {
//       removeAuthInfo();
//       return Promise.reject(err1);
//     }
//     setAuthInfo(result);
//     return true;
//   })

//   setAuthInfo(result1);
//   config.hasRefresh = true;
//   const [err2, result2] = await to<any>(service(config));
//   if (err2) {
//     removeAuthInfo();
//     return Promise.reject(err2);
//   }
//   return result2;
// }

// export async function isRefreshToken(config: InternalAxiosRequestConfig<any>) {
//   return !!config.__isRefreshToken;
// }

export default service;
