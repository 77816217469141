import { Button, Form, Input, Space, message } from "@deeplang/dui";
import cx from "classnames";
import { useCallback, useMemo } from "react";
import { debounce } from "lodash-es";
import styles from "./index.module.css";
import styleCommon from "../style/index.module.css";
import {
  MobileActions,
  ModalShowType,
  AuthenticatedType,
  errorText,
} from "../../utils/constants";
import { useDeepGuard } from "../../index";
// import IconArrow from '../../assets/iconArrow.url.svg';
import to from "../../utils/await-to";
import useCountdown from "../../hooks/useCountDown";
import { LeftOutlined } from "@deeplang/icons";

type FieldType = {
  phone?: string;
  phoneCode?: string;
};

const Authenticated = ({ type }: { type: AuthenticatedType }) => {
  const deepGuard = useDeepGuard();
  const [form] = Form.useForm();

  const { second, start, reset, isCounting } = useCountdown(60, () => {
    reset();
  });

  const verificationCodeBtnText = useMemo(() => {
    if (isCounting) {
      return `${second}秒`;
    }
    return "获取验证码";
  }, [isCounting, second]);

  const actionType = (type: AuthenticatedType) => {
    switch (type) {
      case AuthenticatedType.UpdateMobile:
        return MobileActions.UpdateMobile;
      case AuthenticatedType.PwdForget:
        return MobileActions.ForgetPwd;
      case AuthenticatedType.UpdatePwd:
        return MobileActions.UpdatePwd;
      default:
        return MobileActions.Destroy;
    }
  };

  const onFinish = async (values: any) => {
    if (type === AuthenticatedType.UpdateMobile) {
      onUpdateMobile(values);
      return;
    }

    let checkErr = null;
    if (type !== AuthenticatedType.PwdForget) {
      [checkErr] = await to(deepGuard.checkUser(values.phone));
    }
    if (checkErr) {
      form.setFields([{ name: "phone", errors: [errorText(checkErr.code)] }]);
      return;
    }
    const [err] = await to(
      deepGuard.verifyMobile({
        phone: values.phone,
        code: values.phoneCode,
        action: actionType(type),
      }),
    );

    if (err) {
      if (err.code === 209 || err.code === 204 || err.code === 205) {
        form.setFields([{ name: "phoneCode", errors: [err.msg] }]);
      } else {
        message.error(err.msg);
      }
      return;
    }

    if (type === AuthenticatedType.PwdForget) {
      deepGuard.show({
        showType: ModalShowType.PwdForget,
        phone: values.phone,
      });
    }

    if (type === AuthenticatedType.PwdReset) {
      deepGuard.show(ModalShowType.PwdUpdate);
    }
    if (type === AuthenticatedType.UpdatePwd) {
      deepGuard.show(ModalShowType.PwdUpdate);
    }
    if (type === AuthenticatedType.Destroy) {
      deepGuard.show(ModalShowType.Destroy);
    }
  };

  const onUpdateMobile = async (values: any) => {
    const [err] = await to(deepGuard.checkUser(values.phone));
    if (err) {
      form.setFields([{ name: "phone", errors: [errorText(err.code)] }]);
      return;
    }
    deepGuard.show(ModalShowType.MobileUpdate);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleGetCode = useCallback(
    debounce(async () => {
      getCode();
    }, 500),
    [],
  );

  const getCode = async () => {
    const phoneNumber = await form.validateFields(["phone"]);
    let checkErr = null;

    if (type !== AuthenticatedType.PwdForget) {
      [checkErr] = await to(deepGuard.checkUser(phoneNumber.phone));
    }
    if (checkErr) {
      form.setFields([{ name: "phone", errors: [errorText(checkErr.code)] }]);
      return;
    }

    if (phoneNumber.phone === "") {
      form.setFields([{ name: "phone", errors: ["请输入手机号"] }]);
      return;
    }
    const [err] = await to(
      deepGuard.sendMobileCode({
        phone: phoneNumber.phone,
        action: actionType(type),
      }),
    );

    if (err) {
      message.error(err.msg);
      return;
    }
    start();
  };

  return (
    <div
      className={cx(styleCommon.pr16, styleCommon.pl16)}
      style={{ width: " 292px" }}
    >
      {type === AuthenticatedType.PwdForget && (
        <div
          className={cx(styles.backLogin, styleCommon.fs12)}
          onClick={() => deepGuard.show(ModalShowType.LoginPwd)}
        >
          <LeftOutlined /> 返回登录
        </div>
      )}
      <h3 className={cx(styleCommon.title, styles.title)}>验证用户</h3>
      <Form
        initialValues={{ phone: deepGuard.state?.phone || "" }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        validateTrigger={["onBlur"]}
        form={form}
      >
        <Form.Item<FieldType>
          className={styleCommon.formItemContent}
          name="phone"
          rules={[
            { required: true, message: "请输入手机号" },
            {
              pattern: /^1[3456789]\d{9}$/,
              message: "请输入正确的手机号",
            },
          ]}
          normalize={(value) => {
            value = value && value.replace(/[^\d]+/g, "");
            return value;
          }}
        >
          <Input
            placeholder="请输入手机号"
            style={{ width: "100%" }}
            allowClear
          />
        </Form.Item>

        {type !== AuthenticatedType.UpdateMobile && (
          <Form.Item
            name="phoneCodeGroup"
            wrapperCol={{ span: 24 }}
            className={cx(styleCommon.mb0, styleCommon.formItemContent)}
          >
            <Space align="start">
              <Form.Item<FieldType>
                name="phoneCode"
                rules={[{ required: true, message: "请输入验证码" }]}
              >
                <Input name="phoneCode" placeholder="请输入验证码" allowClear />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  disabled={isCounting}
                  className={cx({
                    [styleCommon.btnDisabled || ""]: isCounting,
                  })}
                  onClick={() => handleGetCode()}
                >
                  {verificationCodeBtnText}
                </Button>
              </Form.Item>
            </Space>
          </Form.Item>
        )}

        {type === AuthenticatedType.PwdForget && (
          <p className={cx(styleCommon.fs12, styleCommon.mb20)}>
            重置密码需要完成手机号验证，确认本人操作。
          </p>
        )}
        {type === AuthenticatedType.UpdatePwd && (
          <p className={cx(styleCommon.fs12, styleCommon.mb20)}>
            修改密码需要完成手机号验证，确认本人操作。
          </p>
        )}
        {type === AuthenticatedType.UpdateMobile && (
          <p className={cx(styleCommon.fs12, styleCommon.mb20)}>
            修改手机号需要完成验证，确认本人操作。
          </p>
        )}
        {type === AuthenticatedType.Destroy && (
          <p className={cx(styleCommon.fs12, styleCommon.mb20)}>
            注销账户需要完成手机号验证，确认本人操作。
          </p>
        )}
        <Form.Item
          wrapperCol={{ span: 24 }}
          className={styleCommon.formItemContent}
        >
          <Button
            type="primary"
            className={cx(styleCommon.fullWidth)}
            htmlType="submit"
          >
            确定
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Authenticated;
