"use client";

import { Button, Form, Input, message } from "@deeplang/dui";
import cx from "classnames";
import {
  PassportEvent,
  PassportEventMsg,
  PasswordActions,
  useDeepGuard,
} from "../../index";
import to from "../../utils/await-to";
import { HiddenOutlined, VisibleOutlined } from "@deeplang/icons";

import styleCommon from "../style/index.module.css";

type FieldType = {
  password?: string;
  passwordConfirm?: string;
};

const PasswordSetting = () => {
  const deepGuard = useDeepGuard();

  const [form] = Form.useForm();

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (values: any) => {
    const { password } = values;
    const data = {
      newPwd: password,
      action: PasswordActions.SettingPwd,
    };
    const [err] = await to(deepGuard.updatePassword(data));

    if (err) {
      message.error(err.msg);
      return;
    }
    message.success("密码设置成功");
    PassportEvent.emit(PassportEventMsg.PASSWORD_SETTING);
    deepGuard.hide();
  };

  return (
    <div
      className={cx(styleCommon.pl16, styleCommon.pr16)}
      style={{ width: " 292px" }}
    >
      <h3 className={styleCommon.title}>设置密码</h3>
      <Form
        form={form}
        validateTrigger={["onBlur"]}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item<FieldType>
          className={styleCommon.formItemContent}
          name="password"
          rules={[
            {
              required: true,
              message: "至少为6个字符，需包含数字和字母，不支持符号",
            },
            {
              pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
              message: "至少为6个字符，需包含数字和字母，不支持符号",
            },
          ]}
        >
          <Input.Password
            name="password"
            placeholder="请输入密码"
            maxLength={50}
            iconRender={(visible: boolean) =>
              visible ? <VisibleOutlined /> : <HiddenOutlined />
            }
          />
        </Form.Item>
        <Form.Item
          className={styleCommon.formItemContent}
          name="passwordConfirm"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: "请输入密码",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("两次密码输入不一致!"));
              },
            }),
          ]}
        >
          <Input.Password
            name="password"
            placeholder="请再次输入密码"
            maxLength={50}
            iconRender={(visible: boolean) =>
              visible ? <VisibleOutlined /> : <HiddenOutlined />
            }
          />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 24 }}
          className={cx(styleCommon.formItemContent, styleCommon.mb8)}
        >
          <Button
            type="primary"
            className={cx(styleCommon.fullWidth)}
            htmlType="submit"
          >
            确定
          </Button>
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 24 }}
          className={styleCommon.formItemContent}
        >
          <Button
            className={cx(styleCommon.fullWidth)}
            onClick={() => {
              PassportEvent.emit(PassportEventMsg.PASSWORD_SETTING);
              deepGuard.hide();
            }}
          >
            以后
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordSetting;
