// INSERT COMPONENT HERE
import { MultiDocIconCustom, UploadFOLDERCustom, UploadPDFCustom, LimitedFreeCustom, NewCustom, PaymentCustom } from "./..";
var boldIcons = [
// INSERT DEMO HERE
{
  name: "MultiDocIconCustom",
  Component: MultiDocIconCustom
}, {
  name: "UploadFOLDERCustom",
  Component: UploadFOLDERCustom
}, {
  name: "UploadPDFCustom",
  Component: UploadPDFCustom
}, {
  name: 'PaymentCustom',
  Component: PaymentCustom
}, {
  name: 'NewCustom',
  Component: NewCustom
}, {
  name: 'LimitedFreeCustom',
  Component: LimitedFreeCustom
}];
export default boldIcons;