import { defaultUserInfo } from "./useShare";
import {
  IMobileSMS,
  ILoginMobileSms,
  Options,
  IUseLoginReturn,
  IVerifyMobile,
  IUseShareReturn,
  ILoginAccount,
  ISendMobileCode,
  IUserInfo,
  IForgetPassword,
  ICheckIsSafe,
} from "../type";
import apiUser from "../api/user";
import apiLogin from "../api/login";
import to, { ApiResponseError } from "../utils/await-to";
import { useParams, useSearchParams } from "next/navigation";

import { removeAuthInfo, setAuthInfo } from "../utils";
import { encrypt } from "../utils/encrypt";
import { MobileActions } from "../utils/constants";
import { PassportEvent, PassportEventMsg } from "../utils/event-emitter";
import { LoginFromType, ProductSource } from "../constants/enum";
import passportConfig from "../config";

interface IProps extends Options, IUseShareReturn {}

function useLogin(props: IProps): IUseLoginReturn {
  const { source, subSource, state, updateState, logout } = props;
  //   const params = useSearchParams();
  //   console.log("---666---useLogin--------bid---------", params.get("bid"));

  // 电话号码验证：登录、注册、修改密码、修改手机号等前置验证电话号码操作
  const _mobileSms = async (params: ILoginMobileSms) => {
    updateState({ btnLoading: true });
    const data: any = { ...params };
    data.source = source || 0;
    data.sub_source = subSource;
    data.phone = encrypt(params.phone);
    if (params.action === MobileActions.BindMobile) {
      data.open_info = state.openInfo;
    }
    const [err, result] = await to(apiLogin.mobileSms(data));
    updateState({ btnLoading: false });
    if (err) return Promise.reject(err);
    return result;
  };

  // 第三方登录和登录成功后的操作
  const loginShare = async (
    data = defaultUserInfo,
    type: string,
    err: ApiResponseError | null,
  ) => {
    PassportEvent.emit(PassportEventMsg.LOGIN, {
      type,
      error: err,
      result: data,
    });

    if (err) {
      removeAuthInfo();
      return Promise.reject(err);
    }

    setAuthInfo(data);

    try {
      if (state?.loginCallback) {
        await state.loginCallback(data);
      }
      updateState({
        userInfo: data,
        hadLogin: true,
        logged: true,
        loginCallback: null,
      });
    } catch (error) {
      removeAuthInfo();
      console.error(error);
    }

    return data;
  };

  // 发送验证码
  const sendMobileCode = async (params: ISendMobileCode) => {
    const phone = encrypt(params.phone);
    const data = {
      phone,
      source,
      action: params.action || MobileActions.Login,
    };
    const [err, result] = await to(apiLogin.sendCode(data));
    if (err) return Promise.reject(err);
    return result;
  };

  // 手机号验证码校验
  const verifyMobile = async (params: IVerifyMobile) => {
    const [err, result] = await to(_mobileSms(params));
    if (err) return Promise.reject(err);
    return result;
  };

  // 账号密码登录
  const loginAccount = async (params: ILoginAccount) => {
    updateState({ btnLoading: true });
    const pwd = encrypt(params.pwd);
    const account = encrypt(params.account);
    const data = { account, pwd, source, sub_source: subSource };
    const [err, result] = await to(apiLogin.loginAccount(data));
    updateState({ btnLoading: false });
    return loginShare(result, LoginFromType.ACCOUNT, err);
  };

  // 电话号码+验证码 登录
  const loginMobile = async (params: IMobileSMS) => {
    const data = { ...params, action: MobileActions.Login };
    const [err, result] = await to(_mobileSms(data));
    return loginShare(result, LoginFromType.PHONE, err);
  };

  const loginWeChat = async (data: IUserInfo) => {
    return loginShare(data, LoginFromType.WECHAT, null);
  };

  const loginPlugin = async (data: IUserInfo) => {
    return loginShare(data, LoginFromType.PLUGIN, null);
  };

  // 绑定手机号
  const bindMobile = async (params: IMobileSMS) => {
    const data: any = { ...params };
    data.action = MobileActions.BindMobile;
    data.open_info = state.openInfo;
    const [err, result] = await to(_mobileSms(data));

    PassportEvent.emit(PassportEventMsg.LOGIN, {
      error: err,
      type: LoginFromType.BIND_PHONE,
      result,
    });
    if (err) {
      return Promise.reject(err);
    }

    setAuthInfo(result);

    try {
      if (state?.loginCallback) {
        await state.loginCallback(data);
      }
      updateState({
        userInfo: result,
        hadLogin: true,
        logged: true,
        loginCallback: null,
      });
    } catch (error) {
      removeAuthInfo();
      console.error(error);
    }

    return result;
  };

  // 修改手机号
  const updateMobile = async (params: IMobileSMS) => {
    const data: any = { ...params };
    data.action = MobileActions.UpdateMobile;
    const [err, result] = await to(_mobileSms(data));
    if (err) return Promise.reject(err);
    return result;
  };

  const loginQRCode = async () => {
    const state = encrypt(
      JSON.stringify({
        source,
        subSource,
      }),
    );
    
    // @ts-expect-error
    new WxLogin({
      self_redirect: true,
      id: "weixin-login-container",
      appid: passportConfig.appId,
      scope: "snsapi_login",
      redirect_uri: process.env.NEXT_PUBLIC_PASSPORT_CALLBACK_URL || "",
      state: encodeURIComponent(state),
      // .impowerBox .title {display: none;}
      // .impowerBox .qrcode {width: 100%; height: 100%; margin: 0; border: none;}
      // .impowerBox .info {display: none;}
      href: "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDEwMCU7IGhlaWdodDogMTAwJTsgbWFyZ2luOiAwOyBib3JkZXI6IG5vbmU7fQouaW1wb3dlckJveCAuaW5mbyB7ZGlzcGxheTogbm9uZTt9",
    });
  };

  const forgetPassword = async (params: IForgetPassword) => {
    const { phone, pwd } = params;
    const data: any = {
      source,
      phone: encrypt(phone),
      new_pwd: encrypt(pwd),
      action: MobileActions.ForgetPwd,
    };
    const [err, result] = await to(apiLogin.forgetPwd(data));
    if (err) return Promise.reject(err);
    return result;
  };

  // 获取登录状态
  const getLoginStatus = async () => {
    updateState({ loading: true, logged: false });
    const [err, result] = await to(apiUser.getUserinfo());
    if (err) {
      updateState({ hadLogin: false, loading: false, logged: false });
      return false;
    }
    updateState({
      userInfo: result,
      hadLogin: true,
      loading: false,
      logged: true,
    });
    return true;
  };

  // check已经注册的产品线
  const checkProduct = async (phone: string) => {
    const data = { phone: encrypt(phone) };
    const [err, result] = await to(apiLogin.checkProduct(data));
    if (err) return Promise.reject(err);
    return result;
  };

  // 桌面端跳转过来后的逻辑
  // 1.校验信息
  // 2.如果校验失败，则跳转登录页
  // 3.如果校验成功，获取用户信息
  // 4.获取到用户信息之后，调用loginShare，更新用户信息

  const checkIsSafe = async (data: ICheckIsSafe) => {
    const [err, result] = await to(
      apiUser.checkLoginInfoSecurity({
        bid: data?.bid,
        uid: data?.uid,
        auth_token: data?.authToken,
        access_token: data?.accessToken,
      }),
    );
    if (err) return Promise.reject(err);
    // setHeader
    const [err2, result2] = await to(apiUser.getUserinfo());

    if (err2) {
      updateState({ hadLogin: false, loading: false, logged: false });
      return false;
    }
    return loginShare(result2, LoginFromType.ACCOUNT, err);
  };

  return {
    logout,
    loginAccount,
    loginMobile,
    loginWeChat,
    loginPlugin,
    loginQRCode,
    verifyMobile,
    bindMobile,
    updateMobile,
    sendMobileCode,
    forgetPassword,
    getLoginStatus,
    checkProduct,
    checkIsSafe,
  };
}

export default useLogin;
