export { default as Aa } from "./Aa";
export { default as ArrowDown } from "./ArrowDown";
export { default as ArrowDownIcon } from "./ArrowDownIcon";
export { default as ArrowRight } from "./ArrowRightn";
export { default as ArrowUp } from "./ArrowUp";
export { default as ArrowUpIcon } from "./ArrowUpIcon";
export { default as BackOriginal } from "./BackOriginal";
export { default as Foo } from "./Bar";
export { default as CheckIcon } from "./Check";
export { default as CheckCircleOutlined } from "./CheckCircleOutlined";
export { default as Clear } from "./Clear";
export { default as ClockWise } from "./ClockWise";
export { default as Close } from "./Close";
export { default as CloseCircle } from "./CloseCircle";
export { default as CloseOutlined } from "./CloseOutlined";
export { default as CloseSearchBox } from "./CloseSearchBox";
export { default as CloseTagIcon } from "./CloseTag";
export { default as Color } from "./Color";
export { default as ColorSelect } from "./ColorSelect";
export { default as Copy } from "./Copy";
export { default as CopySimpleLined } from "./CopySimpleLined";
export { default as Delete } from "./Delete";
export { default as DeleteLined } from "./DeleteLined";
export { default as DisLike } from "./DisLike";
export { default as DislikeFilled } from "./DislikeFilled";
export { default as Divider } from "./Divider";
export { default as DotFilled } from "./DotFilled";
export { default as DotsThree } from "./DotsThree";
export { default as Download } from "./Download";
export { default as DownloadSimpleLined } from "./DownloadSimpleLined";
export { default as Editor } from "./Editor";
export { default as EmptyInfo } from "./EmptyInfo";
export { default as Error } from "./Error";
export { default as Favorite } from "./Favorite";
export { default as Fifty } from "./Fifty";
export { default as FilePdfLined } from "./FilePdfLined";
export { default as FilterIcon } from "./FilterIcon";
export { default as FilterPopupCheckIcon } from "./FilterPopupCheckIcon";
export { default as GearSix } from "./GearSix";
export { default as GlobeOutlined } from "./GlobeOutlined";
export { default as GreaterThan } from "./GreaterThan";
export { default as HiddenOutlined } from "./HiddenOutlined";
export { default as IconX } from "./IconX";
export { default as Info } from "./Info";
export { default as LeftOutlined } from "./LeftOutlined";
export { default as Like } from "./Like";
export { default as LikeFilled } from "./LikeFilled";
export { default as LikeLined } from "./LikeLined";
export { default as LinkIcon } from "./Link";
export { default as ListIcon } from "./List";
export { default as Loading } from "./Loading";
export { default as Loading3Quarters } from "./Loading3Quarters";
export { default as Megaphone } from "./Megaphone";
export { default as More } from "./More";
export { default as MyIcon } from "./MyIcon";
export { default as NoteIcon } from "./NoteIcon";
export { default as OrderList } from "./OrderList";
export { default as ParsingIcon } from "./Parsing";
export { default as PdfIcon } from "./PdfIcon";
export { default as PencilColor } from "./PencilColor";
export { default as PercentFifty } from "./PercentFifty";
export { default as PercentHundred } from "./PercentHundred";
export { default as PercentSeventyFive } from "./PercentSeventyFive";
export { default as Plus } from "./Plus";
export { default as PromptOutlined } from "./PromptOutlined";
export { default as Query } from "./Query";
export { default as QuestionCircle } from "./QuestionCircle";
export { default as QuotationMark } from "./QuotationMark";
export { default as Quotes } from "./Quotes";
export { default as Refresh } from "./Refresh";
export { default as Related } from "./Related";
export { default as RepeatOutlined } from "./RepeatOutlined";
export { default as RightOutlined } from "./RightOutlined";
export { default as RssIcon } from "./Rss";
export { default as Search } from "./Search";
export { default as Selected } from "./Selected";
export { default as Share } from "./Share";
export { default as ShareFat } from "./ShareFat";
export { default as ShareFatLined } from "./ShareFatLined";
export { default as SolidCircle } from "./SolidCircle";
export { default as SolidTag } from "./SolidTag";
export { default as Success } from "./Success";
export { default as Tag } from "./Tag";
export { default as TagLined } from "./TagLined";
export { default as Trash } from "./Trash";
export { default as User } from "./User";
export { default as VisibleOutlined } from "./VisibleOutlined";
export { default as Warn } from "./Warn";
export { default as WarningCircle } from "./WarningCircle";
export { default as WarningFilled } from "./WarningFilled";
export { default as WeChat } from "./WeChat";
export { default as WeChatIcon } from "./WeChatIcon";
export { default as WeChatLined } from "./WeChatLined";
export { default as WeChatV2 } from "./WeChatV2";
export { default as WebSite } from "./WebSite";
export { default as Wrapper } from "./Wrapper";
export { default as XCircle } from "./XCircle";
export * from "./icons";
export { default as IconsDemo } from "./icons-demo";
import "./index.css";