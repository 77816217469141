import {
  ModalShowType,
  MobileActions,
  AuthenticatedType,
  PasswordActions,
  PhoneActions,
  LoginType,
  errorText,
  PlatformType,
} from "./utils/constants";

import { ProductSource, ProductSubSource } from "./constants/enum";

import storage from "./utils/storage";
import {
  loginVerification,
  getPrefixKeys,
  postLoginMessage,
  receiveLoginMessage,
  setHeadersAuthInfo,
  getLoginStatus,
} from "./utils";
import deepGuardConfig from "./config";
import { refreshToken } from "./utils/request";
import PassportEvent, { PassportEventMsg } from "./utils/event-emitter";
import DeepLogin from "./login";

export * from "./provider";

export * from "./type";

export {
  PassportEvent,
  storage,
  PlatformType,
  deepGuardConfig,
  MobileActions,
  DeepLogin,
  ModalShowType,
  ProductSource,
  ProductSubSource,
  PasswordActions,
  AuthenticatedType,
  PhoneActions,
  LoginType,
  loginVerification,
  refreshToken,
  errorText,
  getPrefixKeys,
  postLoginMessage,
  receiveLoginMessage,
  setHeadersAuthInfo,
  PassportEventMsg,
  getLoginStatus,
};
