"use client";

import { IState, IUseShareReturn, Options } from "../type";
import { ModalShowType } from "../utils/constants";
import { PassportEvent, PassportEventMsg } from "../utils/event-emitter";

interface IProps extends Partial<Options>, IUseShareReturn {}

function useActions(props: IProps) {
  const { updateState } = props;

  const show = (options: ModalShowType | Partial<IState>, cb?: () => void) => {
    // console.log("show------", options);
    let newStatus: any = {};
    if (typeof options === "number") {
      newStatus.showType = options;
    } else {
      newStatus = options;
    }
    updateState({ ...newStatus, modalVisible: true, loginCallback: cb });
  };

  const hide = () => {
    PassportEvent.emit(PassportEventMsg.MODEL_HIDE);

    updateState({ modalVisible: false });
  };

  function on() {
    // TODO
  }

  function remove() {
    // TODO
  }

  return { on, remove, show, hide };
}

export default useActions;
