// INSERT COMPONENT HERE
import { AiThinkingColor, ChatLoadingColor, ClockCounterClockwiseColor, DotColor, PencilOneColor, PencilThreeColor, PencilTwoColor, PuzzlePieceColor, SignOutCloseColor, SignOutOpenColor } from "./..";
var colorIcons = [
// INSERT DEMO HERE
{
  name: 'PuzzlePieceColor',
  Component: PuzzlePieceColor
}, {
  name: 'SignOutCloseColor',
  Component: SignOutCloseColor
}, {
  name: 'SignOutOpenColor',
  Component: SignOutOpenColor
}, {
  name: 'ClockCounterClockwiseColor',
  Component: ClockCounterClockwiseColor
}, {
  name: 'DotColor',
  Component: DotColor
}, {
  name: 'AiThinkingColor',
  Component: AiThinkingColor
}, {
  name: 'ChatLoadingColor',
  Component: ChatLoadingColor
}, {
  name: 'PencilOneColor',
  Component: PencilOneColor
}, {
  name: 'PencilTwoColor',
  Component: PencilTwoColor
}, {
  name: 'PencilThreeColor',
  Component: PencilThreeColor
}];
export default colorIcons;