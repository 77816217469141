"use client";

import { createContext, useContext } from "react";
import useStore from "./store/useStore";
import { DeepGuardStore, Options } from "./type";

export const DeepGuardContext = createContext<any>({});

export const useDeepGuard = () => {
  const state = useContext<DeepGuardStore & Options>(DeepGuardContext);
  return state;
};

export const usePassport = () => {
  const state = useContext<DeepGuardStore & Options>(DeepGuardContext);
  return state;
};

export function DeepGuardProvider(options: Options): JSX.Element {
  const { children, source, subSource, appId, mode, env } = options;
  const store = useStore({ source, subSource, appId, mode, env });

  return (
    <DeepGuardContext.Provider value={{ ...options, ...store }}>
      {children}
    </DeepGuardContext.Provider>
  );
}
