import { IState, IUseShareReturn, TStateValue } from "../type";
import apiUser from "../api/user";
import to from "../utils/await-to";
import { removeAuthInfo } from "../utils";

import { useState } from "react";
import { produce } from "immer";
import { ModalShowType } from "../utils/constants";

import PassportEvent, { PassportEventMsg } from "../utils/event-emitter";

export const defaultUserInfo = {
  auth_token: "",
  access_token: "",
  user_name: "",
  icon: "",
  uid: "",
  b_id: "",
  binding_phone: false,
  phone: "",
  set_pwd: false,
  open_info: "",
  binding_wechat: false,
  login_type: "",
};

function useShare(): IUseShareReturn {
  // const { source } = props;

  const [state, setState] = useState<IState>({
    showType: ModalShowType.Login,
    modalVisible: false,
    hadLogin: false,
    userInfo: { ...defaultUserInfo },
    openInfo: "",
    loginType: "code",
    loading: true,
    btnLoading: false,
    loginCallback: null,
    logged: false,
  });

  const updateState = (
    options: Partial<IState> | string,
    value?: TStateValue,
  ) => {
    setState((prev) => {
      let newStatus = prev;
      if (typeof options === "string" && value) {
        newStatus = produce(newStatus, (draft: any) => {
          draft[options] = value;
        });
      }
      if (typeof options === "object") {
        newStatus = produce(newStatus, (draft) => {
          Object.assign(draft, options);
        });
      }
      return newStatus;
    });
  };

  const logout = async () => {
    const [error, result] = await to(apiUser.logout());
    PassportEvent.emit(PassportEventMsg.LOGOUT, { error, result });
    if (error) return Promise.reject(error);
    removeAuthInfo();
    updateState({
      userInfo: { ...defaultUserInfo },
      hadLogin: false,
      logged: false,
    });
    return result;
  };

  return { state, updateState, logout };
}

export default useShare;
