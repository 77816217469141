export const SourcePrefix = [
  "",
  "WantWordQuotes",
  "WeApp",
  "Extension",
  "DeepChat",
  "",
  "",
  "Lingo",
];

export const errorText = (code: number) => {
  switch (code) {
    case 204:
      return "验证码错误";
    case 205:
      return "验证码已失效，请重新获取";
    case 206:
      return "该手机号已存在";
    case 207:
      return "不能与原手机号相同";
    case 208:
      return "请输入正确的手机号";
    case 209:
      return "密码错误，再试一次吧";
    case 210:
      return "找不到该账号，请先注册";
    default:
      return "";
  }
};

export const enum MessageType {
  DeepLang = "wechat-login-success",
  Lingo = "LINGO_READER_wechat-login-success"
}

export const enum MessageOrigin {
  DeepLang = "auth.shenyandayi.com",
  Lingo = "auth.lingoreader.cn"
}
