/* global chrome */
/* eslint no-undef: "error" */

interface IStorage {
  set: (key: string, value: any) => void;
  get: (key: string) => any;
  remove: (key: string) => void;
}

// 判断是否是插件环境,TODO: 不同浏览器的判断
// @ts-ignore
// eslint-disable-next-line no-undef
const isExtension = typeof chrome !== "undefined" && chrome?.storage;

class Storage {
  set(key: string, value: string) {
    if (isExtension) {
      // @ts-ignore
      chrome?.storage.local.set({ [key]: value });
    }
    localStorage.setItem(key, value);
  }

  get(key: string) {
    if (isExtension) {
      return new Promise((resolve) => {
        // @ts-ignore
        chrome?.storage.local.get([key], (result: any) => {
          const value = result?.[key] || localStorage.getItem(key) || "";
          resolve(value);
        });
      });
    } else {
      const value = localStorage.getItem(key) || "";
      return value;
    }
  }

  remove(key: string) {
    localStorage.removeItem(key);
    if (isExtension) {
      // @ts-ignore
      chrome?.storage.local.remove([key]);
    }
  }
}

const storage: IStorage = new Storage();

export default storage;
