"use client";

import { useEffect } from "react";
import { useDeepGuard } from "./index";
import LoginModal from "./templates/modal";
import LoginPage from "./templates/plugin";
import { PassportEvent, PassportEventMsg } from "./utils/event-emitter";
import { defaultUserInfo } from "./hooks/useShare";
import { useSearchParams } from "next/navigation";
const DeepLogin = (props: any) => {
  const deepGuard = useDeepGuard();
  const params = useSearchParams();

  const loginExpired = () => {
    deepGuard.updateState({
      userInfo: { ...defaultUserInfo },
      hadLogin: false,
    });
  };

  useEffect(() => {
    PassportEvent.on(PassportEventMsg.LOGIN_EXPIRED, loginExpired);

    return () => {
      PassportEvent.off(PassportEventMsg.LOGIN_EXPIRED, loginExpired);
    };
  }, []);

  return (
    <>
      {deepGuard.mode === "page" && <LoginPage {...props} />}
      {deepGuard.mode === "modal" && deepGuard.state.modalVisible && (
        <LoginModal {...props} />
      )}
    </>
  );
};

export default DeepLogin;
