import { SourcePrefix } from "./constants";
import { ProductName, ProductSubSource } from "./constants/enum";

// const baseUrl = {
//   qa: "http://qa-api.lingowhale.com",
//   pre: "https://pre-api.lingowhale.com",
//   prod: "https://api.lingowhale.com",
// };

const secretKeyMap = {
  // TODO: 自动设置环境变量
  // qa环境
  // 域名：qa-api-passport.shenyandayi.com
  qa: {
    aeskey: "deep#$Auth2024$!",
    aesiv: "iv!@deep$2024##",
  },
  // pre环境
  // 域名：pre-api-passport.shenyandayi.com
  pre: {
    aeskey: "BN$Lz=rN5eh*jw&6hu4@tsBKLaARCZnv",
    aesiv: "yzQXHB=wTD6=Bv=fDgNpykWGCGwNB454",
  },
  // prod环境
  // 域名：api-passport.shenyandayi.com
  prod: {
    aeskey: "BN$Lz=rN5eh*jw&6hu4@tsBKLaARCZnv",
    aesiv: "yzQXHB=wTD6=Bv=fDgNpykWGCGwNB454",
  },
};

export interface IPassportConfig {
  env: "qa" | "pre" | "prod";
  source: number;
  productName?: string;
  subSource?: ProductSubSource;
  prefix?: string;
  appId?: string;
  mode: "modal" | "page";
  config?: {
    privacyPolicyUrl: string;
    userProtocolUrl: string;
    copyrightUrl: string;
  };
}

class DeepGuardConfig {
  private _data: IPassportConfig = {
    env: (process.env.NEXT_PUBLIC_ENV as "qa" | "pre" | "prod") || "pre",
    source: 0,
    productName: ProductName.DeepLang,
    prefix: SourcePrefix[0],
    appId: "",
    mode: "modal",
    config: {
      privacyPolicyUrl: "",
      userProtocolUrl: "",
      copyrightUrl: "",
    },
  };
  get env() {
    return this._data.env;
  }
  get baseUrl() {
    // const env = this._data.env;
    return process.env.NEXT_PUBLIC_API_URL;
  }
  get secretKey() {
    const env = this._data.env;
    return secretKeyMap?.[env] || secretKeyMap.qa;
  }
  get subSource() {
    return this._data.subSource;
  }
  get source() {
    return this._data.source;
  }
  get prefix() {
    return this._data.prefix;
  }
  get mode() {
    return this._data.mode;
  }
  get productName() {
    return this._data.productName;
  }
  get config() {
    return this._data.config;
  }
  get appId() {
    return this._data.appId;
  }
  init(options: IPassportConfig) {
    this._data = options;
  }
  getConfig() {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { source, prefix } = this?._data;
    if (!prefix) {
      const newPrefix = SourcePrefix[source];
      this._data.prefix = newPrefix;
    }
    return this._data;
  }
}

const passportConfig = new DeepGuardConfig();

export default passportConfig;
