// INSERT COMPONENT HERE
import { StackLined, RssPlusLined, SortAscendingLined, WordLogoLined, FilesLined, DotsSixLined, RadioButtonLined, GearLined, ChatDotsLined, BroomLined, ArrowSquareUpRightLined, ArrowSquareDownLeftLined, FileOPLined, ArchiveLined, ArrowDownLined, ArrowLineLeftLined, ArrowLineRightLined, ArrowRingLined, ArrowSquareOutLined, ArrowUpLined, ArrowsInSimpleLined, ArrowsOutLined, ArrowsOutSimpleLined, BookOpenTextLined, BooksLined, CaretDoubleDownLined, CaretDownLined, CaretLeftLined, CaretRightLined, CaretUpLined, ClockLined, CopyLined, CornersInLined, CrossLined, DesktopTowerLined, DotsThreeVerticalLined, DownloadBottomLined, DownloadLined, EnterReturnLined, ExcelLogoLined, ExclamationCircleLined, EyeClosedLined, EyeLined, FatShareLined, FileArrowUpLined, FileHighlightLined, FilePngLined, FileSvgLined, FileXLined, GearSixLined, GitBranchLined, GlobeLined, HouseLined, ImagesLined, InfoCircleLined, InfoLined, KeywordLined, LightningALined, ListBulletsLined, ListNumbersLined, Loading3QuartersLined, LoadingLined, LogoutLined, MinusLined, NoSidebarLined, NoteLined, PdfFileLined, PencilSimpleLineLined, PlusCircleLined, PlusLined, PuzzlePieceLined, QuestionLined, QuotesLined, RelatedLined, RepeatLined, SearchLined, SidebarHalfLined, SidebarLined, SidebarRightLined, SignOutLined, SketchLogoLined, SpinnerGapLined, SpreadLeftLined, SpreadRightLined, StarLined, TagSimpleLined, TextAaLined, ThumbsDownLined, ThumbsUpLined, TrashLined, UploadSimpleLined, UserLined, VectorLined, WechatLogoLined, XCircleLined } from "./..";
var linedIcons = [
// INSERT DEMO HERE
{
  name: "StackLined",
  Component: StackLined
}, {
  name: "RssPlusLined",
  Component: RssPlusLined
}, {
  name: "SortAscendingLined",
  Component: SortAscendingLined
}, {
  name: "WordLogoLined",
  Component: WordLogoLined
}, {
  name: "FilesLined",
  Component: FilesLined
}, {
  name: "DotsSixLined",
  Component: DotsSixLined
}, {
  name: "RadioButtonLined",
  Component: RadioButtonLined
}, {
  name: "GearLined",
  Component: GearLined
}, {
  name: "ChatDotsLined",
  Component: ChatDotsLined
}, {
  name: "BroomLined",
  Component: BroomLined
}, {
  name: "ArrowSquareUpRightLined",
  Component: ArrowSquareUpRightLined
}, {
  name: "ArrowSquareDownLeftLined",
  Component: ArrowSquareDownLeftLined
}, {
  name: "FileOPLined",
  Component: FileOPLined
}, {
  name: 'FileXLined',
  Component: FileXLined
}, {
  name: 'FilePngLined',
  Component: FilePngLined
}, {
  name: 'FileSvgLined',
  Component: FileSvgLined
}, {
  name: 'CornersInLined',
  Component: CornersInLined
}, {
  name: 'SidebarRightLined',
  Component: SidebarRightLined
}, {
  name: 'SidebarLined',
  Component: SidebarLined
}, {
  name: 'FileArrowUpLined',
  Component: FileArrowUpLined
}, {
  name: 'LightningALined',
  Component: LightningALined
}, {
  name: 'CornersInLined',
  Component: CornersInLined
}, {
  name: 'SidebarRightLined',
  Component: SidebarRightLined
}, {
  name: 'SidebarLined',
  Component: SidebarLined
}, {
  name: 'FileArrowUpLined',
  Component: FileArrowUpLined
}, {
  name: 'SignOutLined',
  Component: SignOutLined
}, {
  name: 'DesktopTowerLined',
  Component: DesktopTowerLined
}, {
  name: 'ArrowLineRightLined',
  Component: ArrowLineRightLined
}, {
  name: 'ArrowLineLeftLined',
  Component: ArrowLineLeftLined
}, {
  name: 'EnterReturnLined',
  Component: EnterReturnLined
}, {
  name: 'GitBranchLined',
  Component: GitBranchLined
}, {
  name: 'ListBulletsLined',
  Component: ListBulletsLined
}, {
  name: 'BookOpenTextLined',
  Component: BookOpenTextLined
}, {
  name: 'BooksLined',
  Component: BooksLined
}, {
  name: 'DownloadBottomLined',
  Component: DownloadBottomLined
}, {
  name: 'KeywordLined',
  Component: KeywordLined
}, {
  name: 'SpinnerGapLined',
  Component: SpinnerGapLined
}, {
  name: 'ArrowsInSimpleLined',
  Component: ArrowsInSimpleLined
}, {
  name: 'NoteLined',
  Component: NoteLined
}, {
  name: 'ArrowsOutSimpleLined',
  Component: ArrowsOutSimpleLined
}, {
  name: 'InfoCircleLined',
  Component: InfoCircleLined
}, {
  name: 'ArrowRingLined',
  Component: ArrowRingLined
}, {
  name: 'UploadSimpleLined',
  Component: UploadSimpleLined
}, {
  name: 'MinusLined',
  Component: MinusLined
}, {
  name: 'ArrowsOutLined',
  Component: ArrowsOutLined
}, {
  name: 'CaretDoubleDownLined',
  Component: CaretDoubleDownLined
}, {
  name: 'XCircleLined',
  Component: XCircleLined
}, {
  name: 'VectorLined',
  Component: VectorLined
}, {
  name: 'UserLined',
  Component: UserLined
}, {
  name: 'SketchLogoLined',
  Component: SketchLogoLined
}, {
  name: 'SearchLined',
  Component: SearchLined
}, {
  name: 'RepeatLined',
  Component: RepeatLined
}, {
  name: 'QuestionLined',
  Component: QuestionLined
}, {
  name: 'PuzzlePieceLined',
  Component: PuzzlePieceLined
}, {
  name: 'PlusCircleLined',
  Component: PlusCircleLined
}, {
  name: 'PlusLined',
  Component: PlusLined
}, {
  name: 'PencilSimpleLineLined',
  Component: PencilSimpleLineLined
}, {
  name: 'LogoutLined',
  Component: LogoutLined
}, {
  name: 'InfoLined',
  Component: InfoLined
}, {
  name: 'HouseLined',
  Component: HouseLined
}, {
  name: 'GearSixLined',
  Component: GearSixLined
}, {
  name: 'EyeClosedLined',
  Component: EyeClosedLined
}, {
  name: 'EyeLined',
  Component: EyeLined
}, {
  name: 'ExclamationCircleLined',
  Component: ExclamationCircleLined
}, {
  name: 'CrossLined',
  Component: CrossLined
}, {
  name: 'ClockLined',
  Component: ClockLined
}, {
  name: 'ArchiveLined',
  Component: ArchiveLined
}, {
  name: 'QuotesLined',
  Component: QuotesLined
}, {
  name: 'ListNumbersLined',
  Component: ListNumbersLined
}, {
  name: 'TextAaLined',
  Component: TextAaLined
}, {
  name: 'SpreadRightLined',
  Component: SpreadRightLined,
  rotate: 180
}, {
  name: 'SpreadLeftLined',
  Component: SpreadLeftLined
}, {
  name: 'RelatedLined',
  Component: RelatedLined
}, {
  name: 'SidebarHalfLined',
  Component: SidebarHalfLined
}, {
  name: 'NoSidebarLined',
  Component: NoSidebarLined
}, {
  name: 'FileHighlightLined',
  Component: FileHighlightLined
}, {
  name: 'WechatLogoLined',
  Component: WechatLogoLined
}, {
  name: 'ExcelLogoLined',
  Component: ExcelLogoLined
}, {
  name: 'ImagesLined',
  Component: ImagesLined
}, {
  name: 'GlobeLined',
  Component: GlobeLined
}, {
  name: 'PdfFileLined',
  Component: PdfFileLined
}, {
  name: 'FatShareLined',
  Component: FatShareLined
}, {
  name: 'DownloadLined',
  Component: DownloadLined
}, {
  name: 'DotsThreeVerticalLined',
  Component: DotsThreeVerticalLined
}, {
  name: 'CopyLined',
  Component: CopyLined
}, {
  name: 'ArrowSquareOutLined',
  Component: ArrowSquareOutLined
}, {
  name: 'TrashLined',
  Component: TrashLined
}, {
  name: 'ThumbsUpLined',
  Component: ThumbsUpLined
}, {
  name: 'ThumbsDownLined',
  Component: ThumbsDownLined
}, {
  name: 'CaretUpLined',
  Component: CaretUpLined
}, {
  name: 'CaretRightLined',
  Component: CaretRightLined
}, {
  name: 'CaretLeftLined',
  Component: CaretLeftLined
}, {
  name: 'CaretDownLined',
  Component: CaretDownLined
}, {
  name: 'ArrowUpLined',
  Component: ArrowUpLined
}, {
  name: 'ArrowDownLined',
  Component: ArrowDownLined
}, {
  name: 'TagSimpleLined',
  Component: TagSimpleLined
}, {
  name: 'LoadingLined',
  Component: LoadingLined,
  spin: true
}, {
  name: 'Loading3QuartersLined',
  Component: Loading3QuartersLined,
  spin: true
}, {
  name: 'StarLined',
  Component: StarLined
}];
export default linedIcons;