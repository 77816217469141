import React from "react";
import { CaretLeftLined } from "@deeplang/icons";
import styles from "./index.module.css";

interface GobackLoginProps {
  goBack: () => void;
}

function GobackLogin(props: GobackLoginProps) {
  const { goBack } = props;

  return (
    <div className={styles.goback} onClick={goBack}>
      <CaretLeftLined />
      <span>返回手机号登录</span>
    </div>
  );
}
export default GobackLogin;