import { DeepGuardStore, Options } from "../type";

import useLogin from "../hooks/useLogin";
import useUser from "../hooks/useUser";
import useActions from "../hooks/useActions";
import useShare from "../hooks/useShare";
import { LoginType, ModalShowType } from "../utils/constants";
import { useMemo } from "react";
import { ProductSource } from "../constants/enum";
import { MessageOrigin, MessageType } from "../constants";
import storage from "../utils/storage";

function useStore(props: Options): DeepGuardStore {
  const { state, updateState, logout } = useShare();
  const { source } = props;
  const messageType =
    source === ProductSource.Lingo ? MessageType.Lingo : MessageType.DeepLang;
  const messageOrigin =
    source === ProductSource.Lingo
      ? MessageOrigin.Lingo
      : MessageOrigin.DeepLang;

  const shareProps = {
    ...props,
    state,
    updateState,
    logout,
  };

  const userStore = useUser(shareProps);
  const loginStore = useLogin(shareProps);
  const actionsStore = useActions(shareProps);

  const isLogin = useMemo(() => {
    return state.hadLogin && !state.loading;
  }, [state.hadLogin, state.loading]);
  const receiveMessage = (event: {
    type: string;
    data: any;
    origin: string;
  }) => {
    const { type, data } = event.data;
    if (type === messageType && event.origin.includes(messageOrigin)) {
      if (data.binding_phone) {
        actionsStore.hide();
        loginStore.loginWeChat(data);
      } else {
        updateState({
          openInfo: data.open_info,
          loginCallback: state.loginCallback,
        });
        // @ts-expect-error
        actionsStore.show(ModalShowType.MobileBind, state.loginCallback);
      }
    }
  };

  return {
    ...userStore,
    ...loginStore,
    ...actionsStore,
    state,
    isLogin,
    updateState,
    receiveMessage,
    userInfo: state.userInfo,
  };
}

export default useStore;
