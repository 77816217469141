// INSERT COMPONENT HERE
import { KeyWordsFilled, CaretLeftFilled, HotFilled, CaretDownFilled, CaretRightFilled, CheckCircleFilled, DeleteFilled, FavFilled, FilesFilled, InfoFilled, NumberCircleFourFilled, NumberCircleThreeFilled, NumberCircleTwoFilled, PaperPlaneTiltFilled, PlusCircleFilled, PolygonFilled, SidebarFilled, SidebarRightFilled, StopFilled, TagSimpleFilled, ThumbsDownFilled, ThumbsUpFilled, TrashFilled, VectorFilled, WarningCircleFilled, XCircleFilled } from "./..";
import StarFilled from "../icons/StarFilled";
var filledIcons = [
// INSERT DEMO HERE
{
  name: "KeyWordsFilled",
  Component: KeyWordsFilled
}, {
  name: "CaretLeftFilled",
  Component: CaretLeftFilled
}, {
  name: "HotFilled",
  Component: HotFilled
}, {
  name: 'PolygonFilled',
  Component: PolygonFilled
}, {
  name: 'CheckCircleFilled',
  Component: CheckCircleFilled
}, {
  name: 'WarningCircleFilled',
  Component: WarningCircleFilled
}, {
  name: 'NumberCircleFourFilled',
  Component: NumberCircleFourFilled
}, {
  name: 'NumberCircleThreeFilled',
  Component: NumberCircleThreeFilled
}, {
  name: 'NumberCircleTwoFilled',
  Component: NumberCircleTwoFilled
}, {
  name: 'FilesFilled',
  Component: FilesFilled
}, {
  name: 'InfoFilled',
  Component: InfoFilled
}, {
  name: 'CaretDownFilled',
  Component: CaretDownFilled
}, {
  name: 'PaperPlaneTiltFilled',
  Component: PaperPlaneTiltFilled
}, {
  name: 'SidebarRightFilled',
  Component: SidebarRightFilled
}, {
  name: 'SidebarFilled',
  Component: SidebarFilled
}, {
  name: 'StopFilled',
  Component: StopFilled
}, {
  name: 'VectorFilled',
  Component: VectorFilled
}, {
  name: 'FavFilled',
  Component: FavFilled
}, {
  name: 'XCircleFilled',
  Component: XCircleFilled
}, {
  name: 'PlusCircleFilled',
  Component: PlusCircleFilled
}, {
  name: 'TrashFilled',
  Component: TrashFilled
}, {
  name: 'ThumbsUpFilled',
  Component: ThumbsUpFilled
}, {
  name: 'ThumbsDownFilled',
  Component: ThumbsDownFilled
}, {
  name: 'TagSimpleFilled',
  Component: TagSimpleFilled
}, {
  name: 'DeleteFilled',
  Component: DeleteFilled
}, {
  name: 'StarFilled',
  Component: StarFilled
}, {
  name: 'CaretRightFilled',
  Component: CaretRightFilled
}];
export default filledIcons;