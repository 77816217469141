import { defaultUserInfo } from "./useShare";
import { IUpdatePwd, Options, IUseUserReturn, IUseShareReturn } from "../type";
import apiUser from "../api/user";
import to from "../utils/await-to";
import { encrypt } from "../utils/encrypt";
import { getPrefixKeys, removeAuthInfo } from "../utils";
import { MobileActions, PasswordActions } from "../utils/constants";

import storage from "../utils/storage";
import { PassportEvent, PassportEventMsg } from "../utils/event-emitter";

interface IProps extends Partial<Options>, IUseShareReturn {}

function useUser(props: IProps): IUseUserReturn {
  const { logout, state, updateState } = props;

  const updateNickname = async (text: string) => {
    const data = { user_name: text };
    const [err, result] = await to(apiUser.updateUserInfo(data));
    if (err) return Promise.reject(err);
    updateState({ userInfo: { ...state.userInfo, user_name: text } });
    return result;
  };

  const getUserinfo = async () => {
    updateState({ loading: true, logged: false });
    const [err, result] = await to(apiUser.getUserinfo());
    if (err) {
      updateState({ loading: false });
      return Promise.reject(err);
    }
    const { bid, uid, accessToken, authToken } = getPrefixKeys();
    const data = await Promise.all([
      storage.get(bid),
      storage.get(uid),
      storage.get(authToken),
      storage.get(accessToken),
    ]);
    result.b_id = data[0];
    result.uid = data[1];
    result.auth_token = data[2];
    result.access_token = data[3];

    updateState({
      userInfo: result,
      hadLogin: true,
      loading: false,
      logged: true,
    });
    return result;
  };

  const checkUser = async (num: string) => {
    const phone = encrypt(num);
    const [err, result] = await to(apiUser.checkUserPhone({ phone }));
    if (err) {
      return Promise.reject(err);
    }
    return result;
  };

  const updatePassword = async (params: IUpdatePwd) => {
    const { newPwd, oldPwd, action } = params;
    const data: any = { new_pwd: encrypt(newPwd) };
    if (action === PasswordActions.UpdatePwd) {
      data.action = MobileActions.UpdatePwd;
    } else if (action === PasswordActions.ResetPwd) {
      data.action = MobileActions.ResetPwd;
    } else {
      data.action = MobileActions.SettingPwd;
    }

    if (oldPwd) data.old_pwd = encrypt(oldPwd);

    const [error, result] = await to(apiUser.updatePwd(data));
    if (error) return Promise.reject(error);
    // 忘记密码和更新密码都需要退出登录，设置密码时不需要
    if (action !== PasswordActions.SettingPwd) {
      logout();
    }

    return result;
  };

  const destroy = async () => {
    updateState({ btnLoading: true });
    const [error, result] = await to(apiUser.destroy());
    PassportEvent.emit(PassportEventMsg.DESTROYED, { error, result });
    if (error) return Promise.reject(error);
    removeAuthInfo();
    updateState({
      userInfo: { ...defaultUserInfo },
      hadLogin: false,
      logged: false,
      btnLoading: false,
    });
    return result;
  };

  const refreshToken = async () => {
    const [err, result] = await to(apiUser.refreshToken());
    if (err) return Promise.reject(err);
    return result;
  };

  return {
    getUserinfo,
    updatePassword,
    updateNickname,
    checkUser,
    destroy,
    refreshToken,
  };
}

export default useUser;
